import Script from 'next/script';

const Scripts = (props) => {
  return (
    <>
      {props.chatWidget && (
        <Script id="show-crisp" strategy="lazyOnload">
          {`window.$crisp = [];
            window.CRISP_WEBSITE_ID = "cfac612c-2b7a-4e96-aa73-319b5deafc68";
            (function () {
              d = document;
              s = d.createElement("script");
              s.src = "https://client.crisp.chat/l.js";
              s.async = 1;
              d.getElementsByTagName("head")[0].appendChild(s);
            })();`}
        </Script>
      )}
      {props.beamer && (
        <>
          <Script id="show-banner" strategy="lazyOnload">
            {`var beamer_config = {
              product_id: 'dEHHxzIw47832',
              selector: '.beamerButton'
            };`}
          </Script>
          <Script id="getbeamer-js" src="https://app.getbeamer.com/js/beamer-embed.js" defer="defer" />
        </>
      )}
    </>
  );
};

export default Scripts;

import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useRef } from 'react';

import { Button } from 'react-bootstrap';
import Footer from '../Client/Components/Footer';
import Scripts from '../Client/Components/Scripts';
import SEO from '../Client/Components/SEO';

export default function HomeMain() {
  console.log(process.env.NODE_ENV);
  const usernameRef = useRef(null);
  const router = useRouter();

  const onClickGetStarted = () => {
    const username = usernameRef.current.value;
    if (username !== '') {
      router.push({
        pathname: '/register',
        query: {
          username: username,
        },
      });
    } else {
      router.push('/register');
    }
  };
  return (
    <>
      <SEO title="Everything is in one simple link" desc="1LinsApp is a free and elegant link-in-bio tool for creators. Claim your name in less than a minute! Free forever. No payment needed. " />
      <Scripts chatWidget={true} beamer={true} />
      <div className="home_page">
        {/* <button
  type="button"
  onClick={() => {
    throw new Error("Sentry Frontend Error");
  }}
>
  Throw error
</button> */}
        <div className="container mt-4">
          <header className="px-3">
            <div className="row">
              <div className="col-4">
                <div className="logo">
                  <Image src="/1links.svg" width="109" height={'40'} alt="1Links App" layout="fill" />
                </div>
                {/* <span className="badge text-bg-primary">Beta</span> */}
              </div>
              <div className="col-8 text-end">
                <div className="btn btn-link beamerButton">What&apos;s new</div>

                <Link href="/login">
                  <a className="btn btn-outline-dark mx-3">Login</a>
                </Link>
                <Link href="/register">
                  <a className="btn btn-dark">Register</a>
                </Link>
              </div>
            </div>
          </header>
        </div>
        <main>
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-md-6 homeleft">
                <h1 className="headline">
                  Everything is in
                  <br />
                  <span className="highlight">One Simple Link</span>
                </h1>
                <div className="subhead py-3">Connect audiences to all of your content with just one link</div>

                <div className="mt-3 w-500 mob-w-100">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon3">
                      1links.app/
                    </span>
                    <input type="text" ref={usernameRef} className="form-control form-control-lg" placeholder="username" aria-describedby="basic-addon3" />
                  </div>

                  <button type="button" className="btn btn-primary btn-lg" onClick={onClickGetStarted}>
                    Get started for free
                  </button>

                  <p className="mt-3">It’s free, and takes less than a minute</p>

                  <div className="row mt-5">
                    <div className="col text-center">
                      <Image src="/home/free-forever.svg" width="24" height={'24'} alt="Free forever" />
                      <div>Free forever</div>
                    </div>

                    <div className="col text-center">
                      <Image src="/home/theme.svg" width="24" height={'24'} alt="20+ themes" />
                      <div>20+ themes</div>
                    </div>

                    <div className="col text-center">
                      <Image src="/home/visitor-analytics.svg" width="24" height={'24'} alt="Visitor analytics" />
                      <div>Visitor analytics</div>
                    </div>

                    <div className="col text-center">
                      <Image src="/home/blazing-fast.svg" width="24" height={'24'} alt="Blazing fast (100ms)" />
                      <div>Blazing fast (100ms)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 homeright text-center">
                <div className="phone-mockup">
                  <Image src="/home/home-phone-mockup4.svg" width="620" height={'580'} alt="Phone Mockup" />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <section className="launch_section mb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Launch your page in seconds</h1>
              <div>Create with ease, customize with ease, and share with ease.</div>
            </div>
          </div>

          <div className="row mt-5 gx-5">
            <div className="col-12 col-md-4 mb-3">
              <div className="mb-3">
                <div className="imagebox">
                  <Image src="/home/image1.png" layout="fill" objectFit="fill" alt="Claim your unique link" />
                </div>
              </div>
              <h2>Claim your unique link</h2>
              <p>Choose your username, pick a theme (or design your own), and publish your page.</p>
            </div>

            <div className="col-12 col-md-4 mb-3">
              <div className="mb-3">
                <div className="imagebox">
                  <Image src="/home/image2.png" layout="fill" objectFit="fill" alt="Add all your links" />
                </div>
              </div>
              <h2>Add all your links</h2>
              <p>Add your socials, websites, videos, anything. Your bio link is your new website.</p>
            </div>

            <div className="col-12 col-md-4 mb-3">
              <div className="mb-3">
                <div className="imagebox">
                  <Image src="/home/image3.png" layout="fill" objectFit="fill" alt="Use it everywhere" />
                </div>
              </div>
              <h2>Use it everywhere</h2>
              <p>Use your bio link on your Instagram, Twitter, TikTok, emails, wherever people follow you.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section_bigtext">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 text-center">
              <h1 className="">
                1Links <span className="text-gray">for artists, writers, musicians, podcasters, youtubers, gamers, developers, hobbyists, brands...and you &#128536;</span>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

import Head from 'next/head';

const SEO = (props) => {
  const { title = 'Link in bio tool : The One Link for All Your Links', desc = '1LinsApp is a free and elegant link-in-bio tool for creators. Claim your name in less than a minute!' } = props;
  return (
    <Head>
      <title>{title} | 1LinksApp</title>
      <meta name="description" content={desc} />
      <meta charSet="utf-8" />
      <link rel="icon" href="/favicon.ico" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@1linksapp" />
      <meta name="twitter:image" content="https://1links.app/og-image-min.jpg" />
      <meta property="og:title" content={`${title} | 1LinksApp`} />
      <meta property="og:url" content="https://1links.app" />
      <meta property="description" content={desc} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content="https://1links.app/og-image-min.jpg" />
      <meta name="apple-mobile-web-app-title" content="1Links App" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="manifest" href="/manifest.json" />
      {/* <link rel="canonical" href="https://bio.link"> */}
    </Head>
  );
};
export default SEO;
